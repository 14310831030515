import React, { useState } from 'react';
import {
  array,
  arrayOf,
  bool,
  func,
  shape,
  string,
  oneOf,
  object,
} from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
} from '../../util/urlHelpers';
import { convertMoneyToNumber, convertPrice } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  storeDataToSession,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import {
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  manageDisableScrolling,
  isScrollingDisabled,
} from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  IconClose,
  Button,
  Modal,
  SecondaryButton,
  Form,
  FieldTextInput,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  replicateListingThunk,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';

import AuthorListings from './AuthorListings/AuthorListings';
import { addToCart } from '../../ducks/cart.duck';
import classNames from 'classnames';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import IconCard from '../../components/SavedCardDetails/IconCard/IconCard.js';

import { Form as FinalForm } from 'react-final-form';

import css from './ListingPage.module.css';
import { composeValidators, stringValidator, maxLength, required } from '../../util/validators.js';
import { isPermitted } from '../../util/currentUser.js';
import { feeTypeMapper, REQUIRED_UPFRONT_OPTIONAL, USAGE_MULTIPLE } from '../../util/constants.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const TITLE_MAX_LENGTH = 60;

const { UUID } = sdkTypes;

const createFilterOptions = options =>
  options.map(o => ({ key: `${o.option}`, label: o.label }));

const Notification = props => {
  const { notification, addToCartError } = props;
  const notificationClasses = classNames(
    css.notification,
    addToCartError ? css.error : css.success
  );

  return notification ? (
    <div className={notificationClasses}>{notification}</div>
  ) : null;
};

const getLocationText = (city, postcode, region) => {
  // Trim inputs if they exist, to handle cases like whitespace-only strings
  city = city?.trim();
  postcode = postcode?.trim();
  region = region?.trim();

  // Check if all three are empty
  if (!city && !postcode && !region) {
    return null;
  }

  // Build the message based on available inputs
  let message = ``;

  if (city) {
    message += ` in ${city}`;
  }

  if (region) {
    if (city) {
      message += `, ${region}`;
    } else {
      message += ` in ${region}`;
    }
  }

  if (postcode) {
    if (city || region) {
      message += `, ${postcode}`;
    } else {
      message += ` in ${postcode}`;
    }
  }

  message += '.';

  return message;
};

const CopyListingModal = (props) => {
  const [showFields, setShowFields] = useState(false);

  const {
    show,
    onClose,
    onManageDisableScrolling,
    currentListingTitle,
    listingId,
    currentListingDescription,
    intl,
    onReplicateListing,
    history,
    routes,
    disableCopyListing,
    replicateListingError,
    replicateListingInProgress
  } = props;

  const formIntials = { title: currentListingTitle, description: currentListingDescription }
  const replicateListingErrorMsg = intl.formatMessage({ id: "ListingPage.CopyListingModal.replicateListingError" })

  // title validators
  const maxLengthMessage = intl.formatMessage(
    { id: 'ListingPage.CopyListingModal.maxLength' },
    {
      maxLength: TITLE_MAX_LENGTH,
    }
  );
  const titleRequiredMessage = intl.formatMessage({ id: 'ListingPage.CopyListingModal.titleRequired' });
  const mustChangeTitleMessage = intl.formatMessage({ id: 'ListingPage.CopyListingModal.mustChangeTitle' }, { words: 'one' });

  const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
  const titleValueValidator = stringValidator({ message: mustChangeTitleMessage, currentStr: currentListingTitle, shouldDifferentBy: 1 })
  const titleValidators = composeValidators(required(titleRequiredMessage), maxLength60Message, titleValueValidator);

  // Description validators
  const descriptionRequiredMsg = intl.formatMessage({
    id: 'ListingPage.ReplicateModal.descriptionRequired',
  });
  const mustChangeDescriptionMsg = intl.formatMessage({ id: 'ListingPage.CopyListingModal.mustChangeDescription' }, { words: 'ten' });
  const descriptionRequired = required(descriptionRequiredMsg);
  const descriptionValueValidator = stringValidator({ message: mustChangeDescriptionMsg, currentStr: currentListingDescription, shouldDifferentBy: 10 })

  const descriptionValidators = composeValidators(descriptionRequired, descriptionValueValidator)

  const handleReplicateListing = (values) => {
    const { title, description } = values;
    onReplicateListing({ listingId, title, description })
      .then(res => {
        const { listingId, type } = res;
        history.push(
          createResourceLocatorString(
            'EditListingPage',
            routes,
            { slug: 'draft', id: listingId, type, tab: 'details' }
          )
        );
      });
  };

  if (disableCopyListing) {
    return null;
  }

  return (
    <Modal
      className={css.copyListingModal}
      isOpen={show}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      {showFields ? <FinalForm
        initialValues={formIntials}
        onSubmit={handleReplicateListing}
        render={formRenderProps => {
          const { handleSubmit, formId, invalid, submitInProgress } = formRenderProps;

          const submitDisabled = invalid || submitInProgress;

          return (
            <Form className={css.replicateModalFrom} onSubmit={handleSubmit}>
              <span className={css.whyChange}><FormattedMessage id='ListingPage.CopyListingModal.whyChange' /></span>
              {replicateListingError ? <span className={css.replicateError}>{replicateListingErrorMsg}</span> : null}
              <FieldTextInput
                id={`${formId}title`}
                name="title"
                className={css.title}
                type="text"
                label={intl.formatMessage({ id: 'ListingPage.ReplicateModal.title' })}
                placeholder={intl.formatMessage({ id: 'ListingPage.ReplicateModal.titlePlaceholder' })}
                maxLength={TITLE_MAX_LENGTH}
                validate={titleValidators}
              />

              <FieldTextInput
                id={`${formId}description`}
                name="description"
                className={css.description}
                type="textarea"
                label={intl.formatMessage({ id: 'ListingPage.ReplicateModal.description' })}
                placeholder={intl.formatMessage({
                  id: 'ListingPage.ReplicateModal.descriptionPlaceholder',
                })}
                validate={descriptionValidators}
              />
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={replicateListingInProgress}
                disabled={submitDisabled}
              >Replicate</Button>
            </Form>
          );
        }}
      /> : <div>
        <span><FormattedMessage id='ListingPage.replicateListing' /></span>
        <div className={css.actionBtns}>
          <Button className={css.replicateBtn} onClick={() => setShowFields(true)}>
            <FormattedMessage id='ListingPage.replicateButton' />
          </Button>
          <SecondaryButton onClick={onClose}>
            <FormattedMessage id='ListingPage.replicateLaterButton' />
          </SecondaryButton>
        </div>
      </div>}

    </Modal>
  )
}

[{
  key: "checkoutOptions",
  scope: "public",
  schemaType: 'multi-enum',
  includeForListingTypes: ['general'],
  enumOptions: [
    { label: "Charged once per cart only", value: "oncePerCart", disabled: false },
    { label: "Charged once per listing", value: "oncePerListing", disabled: false },
    { label: "Charged once per item", value: "oncePerItem", disabled: false }
  ]
}]

const ShowAdditionalFees = ({ listing }) => {
  const { publicData } = listing.attributes;
  const { additionalFees = [] } = publicData;
  // const otherOptionalCharges = additionalFees?.filter(fee => (fee.feeCalculationType !== USAGE_MULTIPLE) && (fee.whenToApply === REQUIRED_UPFRONT_OPTIONAL));

  if(!additionalFees.length){
    return null;
  };
  
  return (
    <div className={css.additionalFeesList}>
      <h4 className={css.additionalFeesSection}><FormattedMessage id='ListingPage.additionalFees' /></h4>
      {additionalFees.map((fee, index) => (
        <div key={index} className={css.additionalFees}>
          <span className={css.feeType}>{feeTypeMapper[fee.feeType]}</span>
        </div>
      ))}
    </div>
  );
};
export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    selectedCurrency,
    exchangeRates,
    fetchRatesError,
    fetchRatesInProgress,
    authorListings,
    authorListingsInProgress,
    authorListingsError,
    onAddToCart,
    addToCartInProgress,
    addToCartError,
    cartNotification,
    onReplicateListing,
    replicateListingError,
    replicateListingInProgress,
    isVerified,
    totalListings
  } = props;

  const { allowCopy } = parse(location.search);
  const [isCopyListingPopup, setIsCopyListingPopup] = useState(allowCopy);

  // Extract the search part of the URL
  const searchParams = new URLSearchParams(location.search);

  // Get the value of the 'search' parameter (which is another encoded query string)
  const nestedQueryString = searchParams.get('search');

  // Decode the nested query string
  const decodedNestedQueryString = decodeURIComponent(nestedQueryString);

  // Create a new URLSearchParams object for the nested query string
  const nestedParams = new URLSearchParams(decodedNestedQueryString);

  // Get the value of the 'queryID' parameter
  const queryID = nestedParams.get('queryID');


  const rawSlug = rawParams?.slug;
  const listingConfig = config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant =
    rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug =
    rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id &&
    currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage =
    pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return (
      <NamedRedirect
        name="ListingPage"
        params={params}
        search={location.search}
      />
    );
  }

  if (!rawSlug && listingSlug && listingSlug !== 'no-slug') {
    return (
      <NamedRedirect
        name="ListingPage"
        params={params}
        search={location.search}
      />
    );
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return (
      <ErrorPage
        topbar={topbar}
        scrollingDisabled={scrollingDisabled}
        intl={intl}
      />
    );
  } else if (!currentListing.id) {
    // Still loading the listing
    return (
      <LoadingPage
        topbar={topbar}
        scrollingDisabled={scrollingDisabled}
        intl={intl}
      />
    );
  };



  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const { city, postCode, region } = publicData;

  const locationText = getLocationText(city, postCode, region, title);

  const convertedPrice =
    !!price && !fetchRatesError
      ? convertPrice(
        exchangeRates,
        price.amount,
        price.currency,
        selectedCurrency
      )
      : null;

  const richedTitle = richText(title, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
    longWordClass: css.longWord,
  });

  const richTitle = <span>
    {!!city ? <FormattedMessage id="ListingPage.titleWithLocation" values={{ title: richedTitle, city }} /> : richedTitle}
  </span>;

  
  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable &&
    currentListing.author.id.uuid === currentUser.id.uuid;

  const transactionProcessAlias = publicData?.transactionProcessAlias;
  const processName = resolveLatestProcessName(
    transactionProcessAlias?.split('/')?.[0]
  );
  const isBooking = isBookingProcess(processName);
  const isPurchase = isPurchaseProcess(processName);
  const processType = isBooking
    ? 'booking'
      ? isPurchase
      : 'purchase'
    : 'inquiry';

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const noPayoutDetailsSetWithOwnListing =
    isOwnListing &&
    (processType !== 'inquiry' && !currentUser?.attributes?.stripeConnected);
  const payoutDetailsWarning = noPayoutDetailsSetWithOwnListing ? (
    <span className={css.payoutDetailsWarning}>
      <FormattedMessage
        id="ListingPage.payoutDetailsWarning"
        values={{ processType }}
      />
      <NamedLink name="StripePayoutPage">
        <FormattedMessage id="ListingPage.payoutDetailsWarningLink" />
      </NamedLink>
    </span>
  ) : null;

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
  const { storeName, rating } = ensuredAuthor.attributes.profile?.publicData || {};

  const { formattedPrice } = priceData(convertedPrice, selectedCurrency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });

  const usageMultipleCharges = publicData?.additionalFees?.filter(fee => fee.feeCalculationType === USAGE_MULTIPLE) || [];

  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
    lineItems,
    usageMultipleCharges
  });

  const isCurrentlyClosed =
    currentListing.attributes.state === LISTING_STATE_CLOSED;

  const handleOrderSubmit = values => {
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
      price: intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: price.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock';


  const addToCartHandler = params => {
    // Check if item is of current user
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else if (currentUser && isAuthenticated) {
      onAddToCart(config, params, queryID)
        .then(r => {
          const cartPagePath = pathByRouteName('CartPage', routeConfiguration);
          history.push(cartPagePath);
        })
        .catch(() => {
          // do nothing redux will manage.
        });
    } else {
      // Save item to session storage and move user to cartPage.
      const data = {
        ...params,
        quantity: params.quantity ? Number(params.quantity) : null,
        units: params.units ? Number(params.units) : null,
      };
      storeDataToSession(data, 'cart');
      const cartPagePath = pathByRouteName('CartPage', routeConfiguration);
      history.push(cartPagePath);
    }
  };

  const isCurrentUser = currentListing.author?.id?.uuid === currentUser?.id?.uuid
  const disableCopyListing = !isPermitted(currentUser) || isCurrentUser;

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={storeName || authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn
        className={css.pageRoot}
        topbar={topbar}
        footer={<FooterContainer />}
      >
        <CopyListingModal
          disableCopyListing={disableCopyListing}
          show={isCopyListingPopup}
          onClose={() => setIsCopyListingPopup(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          onReplicateListing={onReplicateListing}
          currentListingTitle={title}
          currentListingDescription={description}
          listingId={rawParams.id}
          intl={intl}
          history={history}
          routes={routeConfiguration}
          replicateListingInProgress={replicateListingInProgress}
          replicateListingError={replicateListingError}
        />
        <div className={css.contentWrapperForProductLayout}>

          <div className={css.mainColumnForProductLayout}>
            {currentListing.id && noPayoutDetailsSetWithOwnListing ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
              />
            ) : null}
            {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null}
            <SectionGallery
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
            />
            <div className={css.mobileHeading}>
              <H4 as="h1" className={css.orderPanelTitle}>
                <FormattedMessage
                  id="ListingPage.orderTitle"
                  values={{ title: richTitle }}
                />
              </H4>
            </div>
            <div className={css.mobilePricing}>
              <OrderPanel
                onlyPricingSection
                className={css.productOrderPanel}
                listing={currentListing}
                isOwnListing={isOwnListing}
                onSubmit={handleOrderSubmit}
                showAuthorDetails
                authorLink={
                  <NamedLink
                    className={css.authorNameLink}
                    name="ListingPage"
                    params={params}
                    to={{ hash: '#author' }}
                  >
                     {storeName || authorDisplayName}
                  </NamedLink>
                }
                title={
                  <FormattedMessage
                    id="ListingPage.orderTitle"
                    values={{ title: richTitle }}
                  />
                }
                titleDesktop={
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage
                      id="ListingPage.orderTitle"
                      values={{ title: richTitle }}
                    />
                  </H4>
                }
                payoutDetailsWarning={payoutDetailsWarning}
                author={ensuredAuthor}
                onManageDisableScrolling={onManageDisableScrolling}
                onContactUser={onContactUser}
                monthlyTimeSlots={monthlyTimeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                validListingTypes={config.listing.listingTypes}
                marketplaceCurrency={config.currency}
                dayCountAvailableForBooking={
                  config.stripe.dayCountAvailableForBooking
                }
                marketplaceName={config.marketplaceName}
                showCartButton={true}
                currentUser={currentUser}
                isCurrentUserListing={
                  currentAuthor?.id?.uuid === currentUser?.id?.uuid
                }
                onAddToCart={addToCartHandler}
                addToCartInProgress={addToCartInProgress}
                addToCartError={addToCartError}
                cartNotification={cartNotification}
                routeConfiguration={routeConfiguration}
                isVerified={isVerified}
                reviews={reviews}
                totalListings={totalListings}
              />
            </div>
            <SectionTextMaybe text={description} showAsIngress />
            <SectionDetailsMaybe
              publicData={publicData}
              metadata={metadata}
              listingConfig={listingConfig}
              intl={intl}
            />
            <ShowAdditionalFees listing={currentListing} additionalFeesFields={listingConfig.additionalFees} />
            {listingConfig.listingFields.reduce((pickedElements, config) => {
              const {
                key,
                enumOptions,
                includeForListingTypes,
                scope = 'public',
              } = config;
              const listingType = publicData?.listingType;
              const isTargetListingType =
                includeForListingTypes == null ||
                includeForListingTypes.includes(listingType);

              const value =
                scope === 'public'
                  ? publicData[key]
                  : scope === 'metadata'
                    ? metadata[key]
                    : null;
              const hasValue = value != null;
              return isTargetListingType &&
                config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                ? [
                  ...pickedElements,
                  <SectionMultiEnumMaybe
                    key={key}
                    heading={config?.showConfig?.label}
                    options={createFilterOptions(enumOptions)}
                    selectedOptions={value || []}
                  />,
                ]
                : isTargetListingType &&
                  hasValue &&
                  config.schemaType === SCHEMA_TYPE_TEXT
                  ? [
                    ...pickedElements,
                    <SectionTextMaybe
                      key={key}
                      heading={config?.showConfig?.label}
                      text={value}
                    />,
                  ]
                  : pickedElements;
            }, [])}

            <SectionMapMaybe
              geolocation={geolocation}
              publicData={publicData}
              listingId={currentListing.id}
              mapsConfig={config.maps}
              locationText={locationText}
              title={title}
            />
            <SectionReviews
              reviews={reviews}
              fetchReviewsError={fetchReviewsError}
              id="reviews"
            />
            <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
              showChangeProfileIcon={
                currentAuthor?.id?.uuid === currentUser?.id?.uuid &&
                !currentAuthor.profileImage
              }
              isVerified={isVerified}
              rating={rating}
            />
          </div>
          <div className={css.orderColumnForProductLayout}>
          <div className={css.actionButtons}>
            {!disableCopyListing ? (
              <Button
                className={css.copyButton}
                type="button"
                onClick={() => setIsCopyListingPopup(true)}
              >
                <IconCard brand="copy" />
              </Button>
            ) : null}
            <Button
              className={css.closeListingButton}
              type="button"
              onClick={() => history.goBack()}
            >
              <IconClose rootClassName={css.closeIcon} />
            </Button>
          </div>
            <OrderPanel
              className={css.productOrderPanel}
              listing={currentListing}
              isOwnListing={isOwnListing}
              onSubmit={handleOrderSubmit}
              showAuthorDetails
              authorLink={
                <NamedLink
                  className={css.authorNameLink}
                  name="ListingPage"
                  params={params}
                  to={{ hash: '#author' }}
                >
                  {storeName || authorDisplayName}
                </NamedLink>
              }
              title={
                <FormattedMessage
                  id="ListingPage.orderTitle"
                  values={{ title: richTitle }}
                />
              }
              titleDesktop={
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage
                    id="ListingPage.orderTitle"
                    values={{ title: richTitle }}
                  />
                </H4>
              }
              payoutDetailsWarning={payoutDetailsWarning}
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              onContactUser={onContactUser}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              validListingTypes={config.listing.listingTypes}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={
                config.stripe.dayCountAvailableForBooking
              }
              marketplaceName={config.marketplaceName}
              showCartButton={true}
              currentUser={currentUser}
              isCurrentUserListing={
                currentAuthor?.id?.uuid === currentUser?.id?.uuid
              }
              onAddToCart={addToCartHandler}
              addToCartInProgress={addToCartInProgress}
              addToCartError={addToCartError}
              cartNotification={cartNotification}
              routeConfiguration={routeConfiguration}
              isVerified={isVerified}
              reviews={reviews}
              totalListings={totalListings}
            />
          </div>
        </div>
        <AuthorListings
          intl={intl}
          authorListings={authorListings}
          authorListingsInProgress={authorListingsInProgress}
          authorListingsError={authorListingsError}
          author={ensuredAuthor}
          isVerified={isVerified}
          rating={rating}
        />
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([
      LISTING_PAGE_DRAFT_VARIANT,
      LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    ]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    authorListingsInProgress,
    authorListingsError,
    authorListingsIds = [],
    replicateListingInProgress,
    replicateListingError,
    isVerified,
    totalListings
  } = state.ListingPage;

  const { currentUser, currentUserCurrency } = state.user;
  const {
    exchangeRates,
    fetchRatesError,
    fetchRatesInProgress,
  } = state.exchangeRate;
  const { addToCartInProgress, addToCartError, notification } = state.cart;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const authorListingsRefs = authorListingsIds.map(id => ({
    id,
    type: 'listing',
  }));
  const authorListings = getMarketplaceEntities(state, authorListingsRefs);

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    selectedCurrency: currentUserCurrency,
    exchangeRates,
    fetchRatesError,
    fetchRatesInProgress,
    authorListings,
    authorListingsInProgress,
    authorListingsError,
    addToCartInProgress,
    addToCartError,
    cartNotification: notification,
    replicateListingError,
    replicateListingInProgress,
    isVerified,
    totalListings
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params =>
    dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onAddToCart: (config, params, queryID) =>
    dispatch(addToCart(config, params, queryID)),
  onReplicateListing: (listingId) => dispatch(replicateListingThunk(listingId))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
