import React from 'react'
import { intlShape } from '../../../util/reactIntl';
import { array, bool } from 'prop-types';
import css from './AuthorListings.module.css'
import { H4,ListingCard, NamedLink, Ratings } from '../../../components';
import { propTypes } from '../../../util/types';
import IconCard from '../../../components/SavedCardDetails/IconCard/IconCard';

export default function AuthorListings(props) {
    const {
        intl,
        authorListings,
        authorListingsInProgress,
        authorListingsError,
        author, isVerified, rating } = props;


    // No need to render the component if author don't have the listings
    // progress and error props have been take in case future use.
    if (!authorListings || !authorListings.length || !author || !author?.id) {
        return null
    };

    const profile = author?.attributes?.profile;
    const city = profile?.publicData?.city;
    const displayName = profile.displayName;

    const authorListingsTitle = intl.formatMessage({ id: 'AuthorListings.title' }, {displayName});
    const viewMore = intl.formatMessage({ id: 'AuthorListings.viewMore' });

    const searchString = !!city && !!displayName
        ? `?name=${displayName}&city=${city}`
        : !!displayName
            ? `?name=${displayName}`
            : !!city
                ? `?city=${city}`
                : ''

    const linkProps = { name: 'ProfilePage', params: { id: author.id.uuid }, to: { search: searchString } };
 
    return (
        <div className={css.authorListings}>
            <H4 className={css.title}>{authorListingsTitle} {isVerified ? <IconCard brand="verifiedShield" /> : null} </H4>
            <Ratings className={css.rating} rating={rating}/>
            <div className={css.listings}>
                {authorListings.length > 7 ? <NamedLink {...linkProps} className={css.viewMore}>
                    {viewMore}
                </NamedLink> : null}
                <ul className={css.listingsContainer}>
                    {authorListings.map(l => (<li className={css.listing} key={l.id.uuid}><ListingCard intl={intl} listing={l} /></li>))}
                </ul>
            </div>
        </div>
    )
};

AuthorListings.propTypes = {
    intl: intlShape.isRequired,
    authorListings: array.isRequired,
    authorListingsInProgress: bool,
    authorListingsError: propTypes.error,
    author: propTypes.user.isRequired
};
